.ExcelTable2007 {
	border: 1px solid #B0CBEF;
	border-width: 1px 0px 0px 1px;
	font-size: 11pt;
	font-family: Calibri;
	font-weight: 100;
	border-spacing: 0px;
	border-collapse: collapse;
	padding: 10px;
  overflow: auto;
  
}
table  { margin-top:  20px; display: inline-block; overflow: auto; max-width: 500px; max-height: 300px;}

.ExcelTable2007 TH {
	background-repeat: repeat-x; 
	font-weight: bold;
	font-size: 14px;
	border: 1px solid #881f44;
  background-color: #eb4d6a;
  color: white;
	border-width: 0px 1px 1px 0px;
	height: 17px;
	line-height: 17px;
  overflow: auto;

}

.ExcelTable2007 TD {
	border: 0px;
	background-color: white;
	padding: 0px 4px 0px 2px;
	border: 1px solid #D0D7E5;
	border-width: 0px 1px 1px 0px;
}

.ExcelTable2007 TD B {
	border: 0px;
	background-color: white;
	font-weight: bold;
}

.ExcelTable2007 TD.heading {
	background-color: #f4d548;
	text-align: center;
	border: 1px solid #e68129;
	border-width: 0px 1px 1px 0px;
	font-weight: bold;
}

TH {
	text-align: center;
}
